import { v4 as uuidv4 } from 'uuid';

import { BlogPostProperties } from './BlogPostPage.types';
import { CmsContent } from '@tgg/ui';

export function BlogPostPage({
    titleBox,
    content,
    cmsPage,
}: BlogPostProperties) {
    const contextualProperties = {
        Generic: {
            page: cmsPage,
        },
        ButtonStandaloneCms: {
            ctaPosition: 'homepage body',
        },
    };

    return (
        <>
            {titleBox && <CmsContent schema={titleBox} />}
            {content.map(contentItem => (
                <CmsContent
                    key={uuidv4()}
                    schema={contentItem}
                    contextualProps={contextualProperties}
                />
            ))}
        </>
    );
}
