import { GetServerSidePropsContext } from 'next';
import getConfig from 'next/config';

import { type BlogCategoryPageProperties } from './BlogCategoryPage.types';
import { CmsClient } from '@tgg/micro-services/cms-client';
import { BlogPostsList, CmsContent } from '@tgg/ui';
import { getAllPosts, getCategoryPagesMap } from '@tgg/util';
import { LocalConfig } from 'apps/commerce/next.config.types';
import { mapPosts } from 'libs/ui/src/lib/components/BlogPostsList/BlogPostList.helpers';

const {
    publicRuntimeConfig: { AMPLIENCE_HUB_ID },
    serverRuntimeConfig: { AMPLIENCE_FRESH_API_KEY, AMPLIENCE_FRESH_API_ON },
}: LocalConfig = getConfig();

export function BlogCategoryPage({
    titleBox,
    posts,
}: BlogCategoryPageProperties) {
    return (
        <>
            {titleBox && <CmsContent schema={titleBox} />}
            {posts && <BlogPostsList posts={posts} />}
        </>
    );
}
export const getServerSideProps = async (
    _context: GetServerSidePropsContext,
): Promise<{ props: BlogCategoryPageProperties }> => {
    const { params } = _context;

    const { category, ...restParameters } = params as unknown as Omit<
        BlogCategoryPageProperties,
        'posts'
    >;

    const categoryName = category.name;

    const cmsClient = new CmsClient({
        hubName: AMPLIENCE_HUB_ID,
        freshApiKey: AMPLIENCE_FRESH_API_KEY,
        isFreshOn: AMPLIENCE_FRESH_API_ON,
    });

    const posts = await getAllPosts(cmsClient);
    const categoryPagesMap = await getCategoryPagesMap(cmsClient);

    const filteredPosts = posts.filter(
        ({ details }) =>
            details?.mainSubcategory.parentCategory.name === categoryName ||
            details?.subcategories?.some(
                subcategory => subcategory.parentCategory.name === categoryName,
            ),
    );

    return {
        props: {
            ...restParameters,
            category,
            posts: mapPosts(filteredPosts, categoryPagesMap),
        },
    };
};
