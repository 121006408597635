import { GetServerSidePropsContext } from 'next';
import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';

import { type BlogHomeProperties } from './BlogHomePage.types';
import { CmsClient } from '@tgg/micro-services/cms-client';
import { BlogPostsList, CmsContent } from '@tgg/ui';
import { getAllPosts, getCategoryPagesMap } from '@tgg/util';
import { LocalConfig } from 'apps/commerce/next.config.types';
import { mapPosts } from 'libs/ui/src/lib/components/BlogPostsList/BlogPostList.helpers';

const {
    publicRuntimeConfig: { AMPLIENCE_HUB_ID },
    serverRuntimeConfig: { AMPLIENCE_FRESH_API_KEY, AMPLIENCE_FRESH_API_ON },
}: LocalConfig = getConfig();

export function BlogHomePage({ titleBox, content, posts }: BlogHomeProperties) {
    return (
        <>
            {titleBox && <CmsContent schema={titleBox} />}
            {content.map(contentItem => (
                <CmsContent key={uuidv4()} schema={contentItem} />
            ))}
            {posts && <BlogPostsList posts={posts} />}
        </>
    );
}
export const getServerSideProps = async (
    _context: Omit<GetServerSidePropsContext, 'params'> & {
        params: Omit<BlogHomeProperties, 'posts'>;
    },
): Promise<{ props: BlogHomeProperties }> => {
    const { params } = _context;

    const cmsClient = new CmsClient({
        hubName: AMPLIENCE_HUB_ID,
        freshApiKey: AMPLIENCE_FRESH_API_KEY,
        isFreshOn: AMPLIENCE_FRESH_API_ON,
    });

    const posts = await getAllPosts(cmsClient);
    const categoryPagesMap = await getCategoryPagesMap(cmsClient);

    return {
        props: {
            ...params,
            posts: mapPosts(posts, categoryPagesMap),
        },
    };
};
